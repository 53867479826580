import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware } from 'redux';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { persistStore, persistReducer } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import reducers from '../reducers';

const persistConfig = {
  timeout: 0,
  key: 'root',
  storage,
  stateReconciler: hardSet,
  blackList: ['locations']
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(
    ReduxThunk
  )
)

const persistor = persistStore(store);

export { store, persistor };