import React, { Component } from 'react';
import I18n from '../../../i18n/utils';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavHeader from '../../components/NavHeader/NavHeader';
import { withOktaAuth } from '@okta/okta-react';
import './AuthenticatedRoute.scss';
import { Col, Row, Card } from 'reactstrap';
import { BackArrow } from '../../components/common';

class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <>
        <NavHeader />
        <Col className="DeliveryPartners container">
          <BackArrow title={'HOME'} path={'/'} />
          <Row>
            <Col xs="7">
              <h3 className="card-title">{I18n.t('APP_AUTHENTICATED_ROUTE_TITLE')}</h3>
            </Col>
            <Card>
              <h1>Authenticated Route</h1>
            </Card>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {}
}

export default withOktaAuth(withRouter(connect(
  mapStateToProps,
  {}
)(AuthenticatedRoute)));