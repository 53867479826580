import {
  GET_STORE_LOCATIONS,
  GET_STORE_LOCATIONS_SUCCESS,
  GET_STORE_LOCATIONS_FAILURE,
  SET_SELECTED_LOCATION,
  SET_ASSIGNED_LOCATIONS,
  SET_LOCATION_COUNTRY_CODE
} from '../actions';
import { API_GET } from '../utils';

const LOCATION_API_BASE_URL = process.env.REACT_APP_LOCATION_API_BASE_URL;

export const getStoreLocations = () => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_STORE_LOCATIONS });
    // Fetch Available Locations from cache before API
    const availableLocations = getState().location.availableLocations;

    console.log(availableLocations)
    // If available locations exist, no need to fetch again
    if (!availableLocations.length) {
      try {
        console.log(LOCATION_API_BASE_URL)
        const { data } = await API_GET(LOCATION_API_BASE_URL);

        console.log(data)
        // All Store Locations with nested data
        const allLocations = data.locationList;
        // Fetch Redux assigned locations array of strings from Okta perms
        const { assignedLocations } = getState().location;
        // Set assigned locations strings. If '00000', assign all locations to user. If not, filter allLocations list with storedAssignedLocations' values
        const availableLocations = assignedLocations !== ['00000'] ? allLocations : await filterAvailableLocations(assignedLocations, allLocations);
        // Dispatch to Redux
        dispatch({ type: GET_STORE_LOCATIONS_SUCCESS, payload: availableLocations });
        // Automatically select first available location
        dispatch(setSelectedLocation(availableLocations[0]));
      } catch (err) {
        console.log(err)
        dispatch({ type: GET_STORE_LOCATIONS_FAILURE, payload: err });
      }
    } else {
      dispatch({ type: GET_STORE_LOCATIONS_SUCCESS, payload: availableLocations });
    }
  }
}

const filterAvailableLocations = (assignedLocations, allLocations) => {
  return allLocations.filter(item => {
    return assignedLocations.includes(item.locationNumber);
  });
}

export const setSelectedLocation = (selectedLocation) => {
  return async dispatch => {
    // Dispatch Location
    dispatch({ type: SET_SELECTED_LOCATION, payload: selectedLocation });
    // Determine Country Code for Location
    dispatch(setLocationCountryCode(selectedLocation));
  }
}

export const setLocationCountryCode = (selectedLocation) => {
  return dispatch => {
    let isCanadaStore = false;
    const { locationNumber } = selectedLocation;;

    const parsedLocationNum = parseInt(locationNumber);

    if (parsedLocationNum >= 30000 && parsedLocationNum <= 39999) {
      // Location is a Canada store, set bool to true
      isCanadaStore = true;
    }

    const countryCode = isCanadaStore ? 'CA' : 'US';
    dispatch({ type: SET_LOCATION_COUNTRY_CODE, payload: countryCode });
  }
}

export const setAssignedLocations = (assignedLocations, isStaff) => {
  return async dispatch => {
    dispatch({ type: SET_ASSIGNED_LOCATIONS, payload: assignedLocations });

    // If not staff, manually generate a selected location [MVP]
    if (!isStaff) {
      dispatch(setSelectedLocation({ locationNumber: assignedLocations[0] }));
    } else {
      dispatch(getStoreLocations());
    }
  }
}