import React, { Component } from 'react';
import I18n from '../../../i18n/utils';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, NavItem, Nav, Row, Col, NavbarBrand, UncontrolledTooltip, UncontrolledPopover, Card, CardBody, DropdownItem } from 'reactstrap';

import './NavHeader.scss';

import { endUserSession } from '../../actions';
import LocationSelector from '../LocationSelector/LocationSelector';

class NavHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToken: props.userToken,
      isAuthenticated: false,
      selectedLocation: null,
      popoverOpen: false,
    };

    this.toggleLocationPopover = this.toggleLocationPopover.bind(this);
    this.toggleAvatarPopover = this.toggleAvatarPopover.bind(this);
    this.closeLocationSelector = this.closeLocationSelector.bind(this);
    this.navigateToLanding = this.navigateToLanding.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update User Token
    if (props.userToken !== state.userToken) {
      update.userToken = props.userToken;
    }

    // Update isAuthenticated
    if (props.isAuthenticated !== state.isAuthenticated && props.isAuthenticated != null) {
      update.isAuthenticated = props.isAuthenticated;
    }

    // Update Selected Location
    if (props.selectedLocation !== state.selectedLocation) {
      update.selectedLocation = props.selectedLocation;
    }

    return update;
  }

  toggleLocationPopover() {
    this.setState({ locationPopoverOpen: !this.state.locationPopoverOpen });
  }

  toggleAvatarPopover() {
    this.setState({ avatarPopupOpen: !this.state.avatarPopupOpen });
  }

  async signOut() {
    this.props.endUserSession();
    this.props.authService.logout('/');
  }

  closeLocationSelector() {
    // Simple solution to close Popover containing Location Selector
    document.body.click();
  }

  navigateToLanding(path) {
    this.props.history.replace('/');
  }

  componentWillUnmount() {
    this.setState({ isAuthenticated: false });
  }

  render() {
    return (
      <Navbar className="navbar navbar-expand-sm justify-content-end">
        <NavbarBrand
          className="nav-app-logo-container"
          onClick={this.navigateToLanding}>
          <img
            id="logoTooltip"
            alt=""
            src={require("../../assets/icons/cfa-logo.svg")}
            width="35"
            height="35"
            className="nav-app-logo"
            alt="CFA Logo"
          />
          <UncontrolledTooltip
            placement="bottom-end"
            target="logoTooltip">
            {I18n.t('APP_LANDING_PAGE_TITLE')}
          </UncontrolledTooltip>
        </NavbarBrand>
        <NavbarBrand className="admin-navbar-brand">
          <h3>{I18n.t('APP_SHORT_TITLE')}</h3>
        </NavbarBrand>
        <span className="ml-auto"></span>
        {
          this.state.userToken &&
          this.state.isAuthenticated &&
          <Nav className="flex-grow-0">
              <>
                {this.state.selectedLocation &&
                  <NavItem className="selected-location-container">
                    <Col>
                      {this.state.selectedLocation.core &&
                        <Row className="selected-location-name">
                          {this.state.selectedLocation.core.locationName}
                        </Row>
                      }
                      <Row className="selected-location-number">
                        {this.state.selectedLocation.locationNumber}
                      </Row>
                    </Col>
                  </NavItem>
                }
                {this.state.selectedLocation == null ?
                  <>
                    <img
                      alt=""
                      id="location-popover"
                      className="location-selector-icon"
                      width="23"
                      height="23"
                      src={require(`../../assets/icons/location.svg`)}
                    />
                    <UncontrolledTooltip
                      placement="bottom-end"
                      target="location-popover">
                      {I18n.t('APP_SELECT_LOCATION')}
                    </UncontrolledTooltip>
                  </>
                  :
                  <>
                    <img
                      alt=""
                      id="location-popover"
                      className="location-selector-icon"
                      width="23"
                      height="23"
                      src={require(`../../assets/icons/location-selected.svg`)}
                    />
                    <UncontrolledTooltip
                      placement="bottom-end"
                      target="location-popover">
                      {I18n.t('APP_SELECT_LOCATION')}
                    </UncontrolledTooltip>
                  </>

                }
                <UncontrolledPopover placement="bottom-end" trigger="legacy" isOpen={this.state.locationPopoverOpen} target="location-popover" toggle={this.toggleLocationPopover}>
                  <LocationSelector
                    closeLocationSelector={this.closeLocationSelector}
                  />
                </UncontrolledPopover>
              </>

            {/* Profile Avatar */}
            <>
              <img
                alt=""
                id="avatar-popover"
                className="nav-avatar"
                width="30"
                height="30"
                src={require("../../assets/icons/avatar-dots.svg")}
              />
              <UncontrolledTooltip
                placement="bottom-end"
                target="avatar-popover">
                {I18n.t('APP_PROFILE_TOOLTIP')}
              </UncontrolledTooltip>
              <UncontrolledPopover placement="bottom-end" trigger="legacy" isOpen={this.state.avatarPopupOpen} target="avatar-popover" toggle={this.toggleAvatarPopover}>
                <Card className="nav-avatar-menu">
                  <CardBody>
                    <h3 className="nav-avatar-name">{this.state.userToken.name}</h3>
                    <h4 className="nav-avatar-email">{this.state.userToken.email}</h4>
                    {
                      this.state.selectedLocation &&
                      <>
                        <DropdownItem divider />
                        <a onClick={this.navigateToLanding}>
                          <h3 className="nav-admin-link">{I18n.t('APP_MENU_LANDING')}</h3>
                        </a>
                      </>
                    }
                    <DropdownItem divider />
                    <a onClick={this.signOut}>{I18n.t('APP_MENU_SIGN_OUT')}</a>
                  </CardBody>
                </Card>
              </UncontrolledPopover>
            </>
          </Nav>
        }
      </Navbar>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { userToken } = state.auth;
  const { selectedLocation } = state.location;
  // Return props const for isAuthenticated
  const isAuthenticated = props.authState.isAuthenticated;
  return { userToken, selectedLocation, isAuthenticated };
}

export default withOktaAuth(withRouter(connect(
  mapStateToProps,
  {
    endUserSession
  }
)(NavHeader)));