// Reducers
import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import LocationReducer from './LocationReducer';

// Combine all reducers
const appReducer = combineReducers({
  auth: AuthReducer,
  location: LocationReducer
})

// Parent Reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export default rootReducer;