import React, { Component } from 'react';
import I18n from '../../../i18n/utils';
import { connect } from 'react-redux';
import { InputGroup, Container, Row, Col, DropdownItem } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import './LocationSelector.scss';

import { getStoreLocations, setSelectedLocation } from '../../actions';

class LocationSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableLocations: this.props.availableLocations,
      selectedLocation: this.props.selectedLocation,
      loading: this.props.loading
    };

    this.filterByCallback = this.filterByCallback.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  componentDidMount() {
    // Fetch Locations on component load
    this.props.getStoreLocations();
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update All Locations
    if (props.availableLocations.length !== state.availableLocations.length) {
      update.availableLocations = props.availableLocations;
    }

    // Update Selected Location
    if (props.selectedLocation !== state.selectedLocation) {
      update.selectedLocation = props.selectedLocation;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  setLocation(selectedLocation) {
    this.props.setSelectedLocation(selectedLocation);
    this.props.closeLocationSelector();
  }

  filterByCallback(option, props) {
    return option.core.locationName.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
      option.locationNumber.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
  }

  renderMenuItemChildren = (option) => {
    return (
      <>
        <Row
          className="location-list-item"
          onClick={this.setLocation.bind(this, option)}>
          <Col className="location-item-avatar">
            <img
              alt=""
              className="location-item-avatar"
              src={require('../../assets/icons/store.svg')} />
          </Col>
          <>
            <Col className="location-item-details">
              <h3 className="location-store-name">{option.core.locationName}</h3>
              <h4 className="location-store-number">{option.locationNumber}</h4>
              <p className="location-store-address">
                {option.core.physicalAddress.address1}&nbsp;
                {option.core.physicalAddress.city},&nbsp;
                {option.core.physicalAddress.state}&nbsp;
                {option.core.physicalAddress.zipCode.zip}
              </p>
            </Col>
          </>
        </Row>
        <DropdownItem divider />
      </>
    )
  }

  render() {
    return (
      <Container className="location-selector">
        <h3 className="location-selector-title">{I18n.t('APP_LOCATION_SELECTOR_SELECT')}</h3>
        {
          (
            this.state.availableLocations.length
          ) ?
            <>
              <Row className="input-row">
                <InputGroup>
                  <Typeahead
                    disabled={this.state.loading}
                    className="store-location-input"
                    labelKey="locationNumber"
                    filterBy={this.filterByCallback}
                    autoFocus
                    id="filtering-example"
                    placeholder="Search by Store Name or Number"
                    maxLength="5"
                    selectHintOnEnter={true}
                    paginate={true}
                    onChange={selected => {
                      this.setLocation(selected[0]);
                    }}
                    emptyLabel="No results found"
                    type="text"
                    name="location_number"
                    options={this.state.availableLocations}
                    renderMenuItemChildren={this.renderMenuItemChildren}
                  ></Typeahead>
                </InputGroup>
              </Row>
            </>
            :
            <p className="location-loading-text">Loading Locations...</p>
        }
      </Container>
    );
  }
}

const mapStateToProps = state => {
  let { availableLocations, selectedLocation, loading } = state.location;
  return { availableLocations, selectedLocation, loading };
}

export default connect(
  mapStateToProps,
  {
    getStoreLocations,
    setSelectedLocation
  }
)(LocationSelector);