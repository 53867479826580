import React, { useState, useEffect, useRef } from 'react'
import { Typography, Checkbox, Button } from 'cfa-react-components'
import Select from 'react-select'
import './FilterEditor.scss'
import { stateAbbreviations } from '../Table/StateAbbreviationData'
// File creating the filter editor. 


function FilterEditor({filterDataHandler, filteredActiveCategories}) {

    const [filtersToSend, setFiltersToSend] = useState({});
    const [checkboxStatus, setCheckboxStatus] = useState({})
    const scrollRef = useRef(null);
    const [formattedDropdownInfo, setFormattedDropdownInfo] = useState({})

    function sendObject(e) {
        filterDataHandler(e);
    }

    useEffect(() => {
        const scrollDiv = scrollRef.current;
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }, [scrollRef]);

    useEffect(() => {
        sendObject(filtersToSend);
    }, [filtersToSend])


    useEffect(() => {
        for (var index in Object.keys(filteredActiveCategories)) {
            if (!Object.keys(filtersToSend).includes(Object.keys(filteredActiveCategories)[index])) {
                let updatedVal = {}
                updatedVal[Object.keys(filteredActiveCategories)[index]] = []
                setFiltersToSend(filtersToSend => ({
                    ...filtersToSend,
                    ...updatedVal
                }));
                if (filteredActiveCategories[Object.keys(filteredActiveCategories)[index]]['values'].length <= 5) {
                    let updatedVal2 = {}
                    updatedVal2[Object.keys(filteredActiveCategories)[index]] = new Array(filteredActiveCategories[Object.keys(filteredActiveCategories)[index]]['values'].length).fill(0)
                    setCheckboxStatus(filtersToSend => ({
                        ...filtersToSend,
                        ...updatedVal2
                    }));
                }
            }
        }
    }, [filteredActiveCategories])

    function updateCheckbox(key, value, isChecked) {
        let temp = [...filtersToSend[key]]
        if (isChecked) {
            temp.push(value)
        } else {
            temp = temp.filter(e => e !== value)
        }
        let updatedVal = {}
        updatedVal[key] = temp
        setFiltersToSend(filtersToSend => ({
            ...filtersToSend,
            ...updatedVal
        }));
    }

    function clearFilters() {
        for (var index in Object.keys(filteredActiveCategories)) {
            let updatedVal = {}
            updatedVal[Object.keys(filteredActiveCategories)[index]] = []
            setFiltersToSend(filtersToSend => ({
                ...filtersToSend,
                ...updatedVal
            }));
            setFormattedDropdownInfo(formattedDropdownInfo => ({
                ...formattedDropdownInfo,
                ...updatedVal
            }));
            if (filteredActiveCategories[Object.keys(filteredActiveCategories)[index]]['values'].length <= 5) {
                let updatedVal2 = {}
                updatedVal2[Object.keys(filteredActiveCategories)[index]] = new Array(filteredActiveCategories[Object.keys(filteredActiveCategories)[index]]['values'].length).fill(0)
                setCheckboxStatus(filtersToSend => ({
                    ...filtersToSend,
                    ...updatedVal2
                }));
            }
        }
    }

    function fixCategoryName(str) {
        let element = str.split(/(?=[A-Z])/);
        element[0] = element[0][0].toUpperCase() + element[0].substring(1)
        element = element.join(' ')
        return element
    }

    function capitalizeWords(words) {
        let newWords = []
        words = words.split(' ')
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        
        words = words.join(' ');
        return words
    }
        
    return (
        <div className='filter-box'>
            <div className='header-container'>
                <Typography variant='h3' align='left'>
                    Filter By:
                </Typography>
            </div>
            <div className='filter-content' ref={scrollRef}>
                    {Object.keys(filteredActiveCategories).map((catName, index) => {
                        let labels = filteredActiveCategories[catName]['values'];
                        if (labels.length > 5) {
                            // categories with more than five possible selections use a dropdown select for filtering
                            let temp = []
                            labels.forEach((element) => {
                            let tempObj = {}
                            tempObj['value'] = element
                            tempObj['label'] = Object.keys(stateAbbreviations).includes(element) ? stateAbbreviations[element] : element.toUpperCase()
                            temp.push(tempObj)
                            })
                            labels = temp
                            return (
                                <div className='subfilter-container' key={`subfilter-container-${index}`}>
                                    <Typography variant='overline2' align='left' key={`subname-${index}`}>
                                        {fixCategoryName(catName)}
                                    </Typography>
                                    <Select
                                        key={`select-${index}`}
                                        menuPosition={'fixed'}
                                        value={formattedDropdownInfo[catName] || ''}
                                        options={labels}
                                        onChange={(allSelected) => {
                                        let updatedFormatVal = {}
                                        updatedFormatVal[catName] = []
                                        for (var index in allSelected) {
                                            updatedFormatVal[catName].push(allSelected[index])
                                        }
                                        setFormattedDropdownInfo(formattedDropdownInfo => ({
                                            ...formattedDropdownInfo,
                                            ...updatedFormatVal
                                        }));
                                        let updatedVal = {}
                                        updatedVal[catName] = []
                                        for (var index in allSelected) {
                                            updatedVal[catName].push(allSelected[index]['value'])
                                        }
                                        setFiltersToSend(filtersToSend => ({
                                            ...filtersToSend,
                                            ...updatedVal
                                        }));
                                        }}
                                        isMulti
                                    />
                                </div>
                            )
                        } else {
                            // for categories with five or fewer possible selections, individual checkboxes are rendered for each selection
                            return (
                                <div className='subfilter-container' key={`subfilter-container-${index}`}>
                                    <Typography variant='overline2' align='left' key={`subname-${index}`}>
                                        {fixCategoryName(catName)}
                                    </Typography>
                                    {labels.map((inputString, index) => (
                                    <Checkbox
                                        key={index}
                                        id={`checkbox-${index}`}
                                        label={capitalizeWords(inputString)}
                                        checked={catName in checkboxStatus ? checkboxStatus[catName][index] == 1 : 0}
                                        onChange={(e) => {
                                            updateCheckbox(catName, inputString, e.target.checked)
                                            let newArr = [...checkboxStatus[catName]]
                                            newArr[index] = checkboxStatus[catName][index] == 1 ? 0 : 1
                                            let updatedVal = {}
                                            updatedVal[catName] = newArr
                                            setCheckboxStatus(filtersToSend => ({
                                                ...filtersToSend,
                                                ...updatedVal
                                            }));
                                        }}
                                    />
                                    ))}
                                </div>
                            )
                        }
                    })}
                    <Button
                        size='sm'
                        style={{alignSelf: 'center', justifySelf: 'center', opacity: '0.9'}}
                        onClick={e => clearFilters()}
                    >Clear</Button>
            </div>
        </div>
    )
}

export default FilterEditor