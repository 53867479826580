import React, { useState, useEffect, useRef } from "react";
import { Icon, IconButton, Modal, ModalBody, ModalHeader, Button, Tooltip } from "cfa-react-components";
import Select from "react-select";
import Help from "../HelpSection/Help";
import './ColumnEditor.scss'

const ColumnEditor = ({ categories, sendActiveCats }) => {
  const [show, setShow] = useState(false);
  // const myRef = React.createRef();
  const [formattedCats, setFormattedCats] = useState({});
  const [activeCats, setActiveCats] = useState([
    "locationNumber",
    "locationName",
    "physicalAddress",
    "operatorName",
    "locationStatus",
    "tempCloseFlag",
    "locationFormatType",
    "timeZoneName",
  ]);
  const [formattedActiveCats, setFormattedActiveCats] = useState({});
  const [placeholderActiveCats, setPlaceholderActiveCats] = useState([
    "locationNumber",
    "locationName",
    "physicalAddress",
    "operatorName",
    "locationStatus",
    "tempCloseFlag",
    "locationFormatType",
    "timeZoneName",
  ]);

  useEffect(() => {
    setFormattedCats(formatCats(categories));
  }, [categories]);

  useEffect(() => {
    setFormattedActiveCats(formatCats(activeCats));
    sendActiveCats(activeCats);
  }, [activeCats]);

  function formatCats(cats) {
    let temp = []
    cats.forEach((element) => {
      let tempObj = {};
      tempObj["value"] = element;
      element = element.split(/(?=[A-Z])/);
      element[0] = element[0][0].toUpperCase() + element[0].substring(1);
      element = element.join(" ");
      element = element.replace(/Address(\d+)/g, "Line $1");
      element = element.replace(/Phone Phone/g, "Phone");
      element = element.replace(/Ss/g, "SS");
      element = element.replace(/Pos/g, "POS");
      element = element.replace(/Guid/g, "GUID");
      element = element.replace(/Id/g, "ID");
      element = element.replace(/Rd/g, "RD");
      element = element.replace(/Dc/g, "DC");
      element = element.replace(/Erqa/g, "ERQA");
      element = element.replace(/Ldp/g, "LDP");
      element = element.replace(/Gmt/g, "GMT");
      tempObj["label"] = element;
      temp.push(tempObj);
    });
    return temp;
  }

  function handleClose() {
    setShow(false);
  }

  function handleOpen() {
    setShow(true);
  }

  function handleApplyChanges() {
    setActiveCats(placeholderActiveCats);
    handleClose();
  }

  return (
    <div>
      <Tooltip content="Customize Table Columns" showOnElementEvents={["hover"]}>
        <IconButton
          style={{
            width: "fit-content",
            alignSelf: "center",
            color: "#002F43",
            padding: '3px',
            margin: '5px'
          }}
          onClick={(e) => handleOpen()}
          color="default"
          size="md"
        >
          <div className="pencil-icon">
            <Icon icon="pencil" />
          </div>
        </IconButton>
      </Tooltip>
      <Modal
        style={{ overflow: "none"}}
        onClose={(e) => handleClose()}
        size="xs"
        show={show}        
      >
        <React.Fragment key=".0">
          <ModalHeader
            style={{
              backgroundColor: "#ECEDEE",
              color: "#004F71",
              fontSize: "40px",
              padding: "4%",
              borderRadius: "8px"
            }}
          >
            Edit Columns Displayed
          </ModalHeader>
          <ModalBody>
            <div style={{ margin: "20px" }}>
              <Select
                options={formattedCats}
                defaultValue={formattedActiveCats}
                onChange={(allSelected) => {
                  // allSelected is array of selected states/territories. Update filtersObj and send to table
                  let updatedVal = [];
                  for (var index in allSelected) {
                    updatedVal.push(allSelected[index]["value"]);
                  }
                  setPlaceholderActiveCats(updatedVal);
                }}
                isMulti
              />
            </div>
          </ModalBody>
        </React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ marginBottom: "20px", width: "35%" }}
            color="secondary"
            size="md"
            onClick={handleApplyChanges}
          >
            Apply changes
          </Button>
        </div>
      </Modal>
      <Help/>
    </div>
  );
};

export default ColumnEditor;
