import React, { useState, useEffect, useRef } from "react";
import { ScrollSync } from 'react-scroll-sync';
import NoData from './NoData'
import "./Table.scss";

import TableEntry from "./TableEntry/TableEntry";
import TableHeader from "./TableHeader";

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

const Table = ({setClickedMarker, setFilterCats, keysList, numDisplay, numResults, jsonData, tags, searchData, onTableChange, filterData, sendDisplayTable, returnAbilityToType, activeCats}) => {

    const [pageNumStart, setPageNumStart] = useState(0)
    const [activePageNum, setActivePageNum] = useState(0)
    const [canGoLeft, setCanGoLeft] = useState(false)
    const [canGoRight, setCanGoRight] = useState(true)
    const [displayTable, setDisplayTable] = useState(jsonData)
    const [tagFilteredTable, setTagFilteredTable] = useState(jsonData)
    const [filteredTable, setFilteredTable] = useState(jsonData)
    const [allCats, setAllCats] = useState(keysList)
    const [formattedActiveCats, setFormattedActiveCats] = useState(activeCats)


    // This is the array we use to display page numbers
    const [elementArray, setElementArray] = useState(numDisplay > 0 ? Array.from(
        Array(Math.ceil(jsonData["data"].length / numDisplay)).keys()
      ).map((n) => n + 1) : [0])
    const numberPagesToDisplayBeforeElipses = 3;

    const noDataFound = displayTable["data"].length === 0;


    //This is used to get a previous value of a prop whenever that prop is changed
    //This is used to deal with page numbers and putting the user on the correct page
    //when the number of pages is changed
    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }
    const prevDisplay = usePrevious(numDisplay);


    //This useEffect triggers whenever the text in the search bar changes
    useDidMountEffect(() => {
      filterTableBySearch()
      setActivePageNum(0)
      setPageNumStart(0)
    }, [searchData]);

    // This useEffect triggers the tag functionality and starts off the chain of reaactions
    // (tags -> search) whenever the tags are updated
    useDidMountEffect(() => {
      handleTags()
      setActivePageNum(0)
      setPageNumStart(0)
    }, [tags]);

    //This useEffect triggers the filter functionality and starts off that chain of reactions
    // (filter -> tags -> search) whenever the filters update, OR whenever new categories
    // are added in the column editor
    useDidMountEffect(() => {
      handleFilters()
      setActivePageNum(0)
      setPageNumStart(0)
    }, [filterData, activeCats]);


    //This useEffect sends all of the currently displayed entries back to HomePage to be used in other 
    //components whenever the currently displayed entries change
    useDidMountEffect(() => {
      sendDisplayTable(displayTable['data'])
      returnAbilityToType(true)
    },  [displayTable]);

    //This useEffect triggers the tag functionality whenever filters are changed
    useDidMountEffect(() => {
      handleTags()
    }, [filteredTable])

    //This useEffect triggers the search functionality whenever tags are changed
    useDidMountEffect(() => {
      filterTableBySearch()
    }, [tagFilteredTable])

  //This useEffect updates empty table entries as ~ symbols whenever new categories are added
  useEffect(() => {
    for (var item in displayTable['data']) {
      for (var index in activeCats){
        if (!Object.hasOwn(displayTable['data'][item], activeCats[index])) {
          displayTable['data'][item][activeCats[index]] = '~'
        } else {
          displayTable['data'][item][activeCats[index]] = String(displayTable['data'][item][activeCats[index]])
        }
      }
    }
    filterFormat()
 }, [activeCats]);

  //This useEffect is used to initally set and format the categories to be displayed in
  //the column editor
  useDidMountEffect(() => {
    let temp = []
    keysList.forEach((element) => {
      let tempObj = {}
      tempObj['value'] = element
      element = element.split(/(?=[A-Z])/);
      element[0] = element[0][0].toUpperCase() + element[0].substring(1)
      element = element.join(' ')
      tempObj['label'] = element
      temp.push(tempObj)
    })
    setAllCats(temp)

    let temp2 = []
    activeCats.forEach((element) => {
      let tempObj = {}
      tempObj['value'] = element
      element = element.split(/(?=[A-Z])/);
      element[0] = element[0][0].toUpperCase() + element[0].substring(1)
      element = element.join(' ')
      tempObj['label'] = element
      temp2.push(tempObj)
    })
    setFormattedActiveCats(temp2)
    filterFormat()
  }, [keysList])

  //This use effect is triggered whenever the number of pages changes, and it 
  //is used to reset the styling of the pageNumbers and arrows when the table changes
  useDidMountEffect(() => {
    if (activePageNum + 1 >= Math.ceil(elementArray.length)) {
      setCanGoRight(false)
    } else {
      setCanGoRight(true)
    }

    if (activePageNum === 0) {
      setCanGoLeft(false)
    } else {
      setCanGoLeft(true)
    }
}, [elementArray])

    //This useEffect is used to update what is displayed on the table based on
    //how many entries the user chooses to show
    useDidMountEffect(() => {
        if (numDisplay <= 0) {
            setElementArray([0])
        } else {
            if (jsonData !== displayTable) {
              setElementArray(Array.from(
                Array(Math.ceil(displayTable['data'].length / numDisplay)).keys()
              ).map((n) => n + 1))
            } else {
              setElementArray(Array.from(
                Array(Math.ceil(jsonData["data"].length / numDisplay)).keys()
              ).map((n) => n + 1))
            }
            setActivePageNum(Math.floor((activePageNum * prevDisplay) / numDisplay))
            setPageNumStart(Math.floor((activePageNum * prevDisplay) / numDisplay) * numDisplay)
    }
    }, [numDisplay])

    function returnData(size) {
        onTableChange(size)
    }

    //This method formats the active cateogries so that they display properly when passed over 
    //To the filter component
    function filterFormat() {
      let filterObj = {}
      for (var index in activeCats) {
        filterObj[activeCats[index]] = {}
        filterObj[activeCats[index]]['values'] = []
        filterObj[activeCats[index]]['isUnique'] = true
      }

      jsonData['data'].forEach( (section) => {
        for (var key in section) {
          if (Object.keys(filterObj).includes(key)) {
            if (key !== 'locationPhysicalAddress') {
              if (filterObj[key]['values'].includes(section[key])) {
                filterObj[key]['isUnique'] = false
              } else {
                if (section[key] !== '') {
                  filterObj[key]['values'].push(section[key])
                }
              }
            }
            }
        }
      })

      for (var section in filterObj) {
        filterObj[section]['values'] = filterObj[section]['values'].filter(function(e) { return e !== '~' })
        if (filterObj[section]['values'].length > 100 || filterObj[section]['isUnique'] || filterObj[section]['values'].length <= 1) {
          delete filterObj[section]
        }
      }

      setFilterCats(filterObj)
    }

    //This function filters the location data based on the active filters
    function handleFilters() {
      let filteredEntries = { data: []}
      jsonData['data'].forEach((element) => {
        let tagsFoundForEntry = true
        for (var key in filterData) {
          if (filterData[key].length > 0 && activeCats.includes(key)) {
            let tagFound = false
          filterData[key].forEach((tag) => {
            if (key !== 'locationPhysicalAddress') {
              if (element[key].toLowerCase() === tag.toLowerCase()) {
                tagFound = true
              }
            } else {
              if (element[key].includes(tag)) {
                tagFound = true
              }
            }
          })
          if (tagFound === false) {
            tagsFoundForEntry = false
            break;
          }
          }
        }
        if (tagsFoundForEntry) {
          filteredEntries['data'].push(element)
        }
      })

      if (filteredEntries === jsonData) {
        handleTags()
      } else {
        setFilteredTable(filteredEntries)
      }
    }

    //This function filters the location data based on the active tags
    function handleTags() {
        if (tags.length > 0) {
            let newJson = { data: [] };
            tags.forEach((tag) => {
                filteredTable['data'].forEach((element) => {
                    for (var key in activeCats) {
                        var entryFound = false;
                        var words =  element[activeCats[key]] ? element[activeCats[key]].split(" ") : ['']
                        if (
                            element[activeCats[key]] && element[activeCats[key]]
                              .toLowerCase()
                              .slice(0, tag.length) ===
                            tag.toLowerCase()
                          ) {
                            newJson["data"].push(element);
                            entryFound = true;
                            break;
                          }
                          for (var wordIndex in words) {
                            if (
                              words[wordIndex]
                                .toLowerCase()
                                .slice(0, tag.length) ===
                              tag.toLowerCase()
                            ) {
                              newJson["data"].push(element);
                              entryFound = true;
                              break;
                            }
                          }
                          if (entryFound) {
                            break;
                          }
                    }
                })
            })
            newJson['data'] = [...new Set(newJson['data'])];
                setTagFilteredTable(newJson)
        } else {
            if (filteredTable === jsonData) {
              filterTableBySearch()
            } else {
              setTagFilteredTable(filteredTable)
            }
        }
      }

    //This function filters the location data based on whatever is in the search bar
    function filterTableBySearch() {
        if (searchData === "") {
          returnData(tagFilteredTable['data'].length)
          setElementArray(numDisplay > 0 ? Array.from(
            Array(Math.ceil(tagFilteredTable['data'].length / numDisplay)).keys()
          ).map((n) => n + 1) : [0])
          setDisplayTable(tagFilteredTable)
        } else {
          var newJson = { data: [] };
          tagFilteredTable["data"].forEach((element) => {
            for (var key in activeCats) {
              var entryFound = false;
              var words =  element[activeCats[key]] ? element[activeCats[key]].split(" ") : ['']
              if (
                element[activeCats[key]] && element[activeCats[key]]
                  .toLowerCase()
                  .slice(0, searchData.length) ===
                searchData.toLowerCase()
              ) {
                newJson["data"].push(element);
                entryFound = true;
                break;
              }
              for (var wordIndex in words) {
                if (
                  words[wordIndex]
                    .toLowerCase()
                    .slice(0, searchData.length) ===
                  searchData.toLowerCase()
                ) {
                  newJson["data"].push(element);
                  entryFound = true;
                  break;
                }

                if (searchData.split(" ").length > 1) {
                  if (element[activeCats[key]].toLowerCase().includes(searchData.toLowerCase())) {
                    newJson["data"].push(element);
                    entryFound = true;
                    break;
                  }
                }
              }
              if (entryFound) {
                break;
              }
            }
          });
          returnData(newJson['data'].length)
          setDisplayTable(newJson)
          setElementArray(numDisplay > 0 ? Array.from(
            Array(Math.ceil(newJson['data'].length / numDisplay)).keys()
          ).map((n) => n + 1) : [0])
        }
      }

    //This function handles the functionality of the page numbers
    function handlePageNumber(pageNum) {
        if (
          pageNum > 0 &&
          pageNum - 1 < displayTable["data"].length / numDisplay
        ) {
          setActivePageNum(pageNum - 1)
          setPageNumStart((pageNum - 1) * numDisplay)
          setCanGoLeft(true)
          setCanGoRight(true)
        }
    
        if (!(pageNum - 1 > 0)) {
          setCanGoLeft(false)
        }
    
        if (!(pageNum < displayTable["data"].length / numDisplay)) {
          setCanGoRight(false)
        }
      }

  return (
    <div className="table-container" >
        <div className="table-tbody">
          <ScrollSync style={{width: '100%'}}>
            <div style={{width: '100%'}}>
              <TableHeader activeCats={activeCats} formattedActiveCats={formattedActiveCats} allCats={allCats} keysList={keysList} displayTable={displayTable} handleChange={e => setDisplayTable(e)}></TableHeader>
              <div className="scroll-container">
              {displayTable["data"]
              .slice(pageNumStart, pageNumStart +numDisplay)
              .map((item, index) => (
                <TableEntry
                  key={index}
                  propertiesToDisplay={activeCats}
                  item={item}
                  setClickedMarker={e => setClickedMarker(e)}
                                />
              ))}
              </div>

            </div>
          </ScrollSync>

          <div style={{display: numResults === 0 || activeCats.length === 0 ? 'inline' : 'none'}}>
            <NoData></NoData>
           </div>

        </div>
        <div className="page-num-holder pointer-cursor">
            <div className={ activePageNum + 1 === 1 ? "ellipses" : (activePageNum !== 0 ? "active-page-num-text" : "inactive-page-num-text") }
              onClick={(e) => handlePageNumber(activePageNum)}
            >
              {"<"}
            </div>
            <div
              style={{
                display:
                    activePageNum <=
                    numberPagesToDisplayBeforeElipses - 1 //&& !(activePageNum + numberPagesToDisplayBeforeElipses >= elementArray.length - 1)
                    ? "none"
                    : "inline",
              }}
              className={
                activePageNum === 0
                  ? "active-page-num-text"
                  : "inactive-page-num-text"
              }
              key={1}
              // pageNum={1}
              onClick={(e) => handlePageNumber(1)}
            >
              {1}
            </div>
            <div
              style={{
                display:
                  activePageNum <=
                  numberPagesToDisplayBeforeElipses - 1 //&& !(activePageNum + numberPagesToDisplayBeforeElipses >= elementArray.length - 1)
                    ? "none"
                    : "inline",
              }}
              className="ellipses"
            >
              {"..."}
            </div>

            {elementArray
              .slice(
                activePageNum <=
                  numberPagesToDisplayBeforeElipses - 1
                  ? 0
                  : activePageNum - 1,
                activePageNum <=
                  numberPagesToDisplayBeforeElipses - 1
                  ? numberPagesToDisplayBeforeElipses + 1
                  : activePageNum + 2
              )
              .map((item, index) => (
                <div
                  className={
                    activePageNum === item - 1
                      ? "active-page-num-text"
                      : "inactive-page-num-text"
                  }
                  key={index}
                  // pageNum={item}
                  onClick={(e) => handlePageNumber(item)}
                  style={{
                    display:
                      (activePageNum + numberPagesToDisplayBeforeElipses >= elementArray[elementArray.length - 1] - 1 && elementArray[elementArray.length - 1] - 1 > numberPagesToDisplayBeforeElipses) ? "none": "inline",
                  }}
                >
                  {item}
                </div>
              ))}
            {elementArray
              .slice(
                activePageNum + numberPagesToDisplayBeforeElipses >=
                  elementArray[elementArray.length - 1] - 1
                  ? elementArray[elementArray.length - 1] -
                      1 -
                      numberPagesToDisplayBeforeElipses
                  : activePageNum,
                activePageNum + numberPagesToDisplayBeforeElipses
              )
              .map((item, index) => (
                <div
                  className={
                    activePageNum === item - 1
                      ? "active-page-num-text"
                      : "inactive-page-num-text"
                  }
                  key={index}
                  // pageNum={item}
                  onClick={(e) => handlePageNumber(item)}
                  style={{
                    display:
                      (activePageNum +
                        numberPagesToDisplayBeforeElipses >=
                      elementArray[elementArray.length - 1] - 1
                      && elementArray[elementArray.length - 1] - 1 > numberPagesToDisplayBeforeElipses)
                        ? "inline"
                        : "none",
                  }}
                >
                  {item}
                </div>
              ))}

            <div
              style={{
                display:
                  activePageNum +
                    numberPagesToDisplayBeforeElipses >=
                  elementArray[elementArray.length - 1] - 1
                    ? "none"
                    : "inline",
              }}
              className="ellipses"
            >
              {"..."}
            </div>
            <div
              style={{
                display:
                  activePageNum + numberPagesToDisplayBeforeElipses > elementArray[elementArray.length - 1] - 1
                  || elementArray[elementArray.length - 1] - 1 <= numberPagesToDisplayBeforeElipses ? "none" : "inline" }}
              className={ activePageNum === elementArray[elementArray.length - 1] - 1 ? "active-page-num-text" : "inactive-page-num-text" }
              key={elementArray[elementArray.length - 1]}
              // pageNum={elementArray[elementArray.length - 1]}
              onClick={(e) =>
                handlePageNumber(elementArray[elementArray.length - 1])
              }
            >
              {elementArray[elementArray.length - 1]}
            </div>
            <div
              className={ activePageNum + 1 === elementArray.length ? "ellipses" : (canGoRight ? "active-page-num-text" : "inactive-page-num-text") }
              onClick={(e) =>
                handlePageNumber(activePageNum + 2)
              }
            >
              {">"}
            </div>
          </div>
      </div>
  )
}

export default Table
