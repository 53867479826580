import {
  GET_STORE_LOCATIONS,
  GET_STORE_LOCATIONS_SUCCESS,
  GET_STORE_LOCATIONS_FAILURE,
  SET_SELECTED_LOCATION,
  SET_ASSIGNED_LOCATIONS,
  SET_LOCATION_COUNTRY_CODE,
  //
  END_USER_SESSION
} from '../actions';

const defaultArrayValue = [];

const initialState = {
  availableLocations: defaultArrayValue,
  assignedLocations: defaultArrayValue,
  selectedLocation: null,
  locationCountryCode: 'US',
  loading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_LOCATIONS:
      return { ...state, loading: true }
    case GET_STORE_LOCATIONS_SUCCESS:
      return { ...state, availableLocations: action.payload, loading: false, error: null }
    case GET_STORE_LOCATIONS_FAILURE:
      return { ...state, availableLocations: defaultArrayValue, loading: false, error: action.payload.error };
    case SET_ASSIGNED_LOCATIONS:
      return { ...state, assignedLocations: action.payload, loading: false, error: action.payload.error };
    case SET_SELECTED_LOCATION:
      return { ...state, loading: false, selectedLocation: action.payload }
    case SET_LOCATION_COUNTRY_CODE:
      return { ...state, locationCountryCode: action.payload }
    case END_USER_SESSION:
      return { ...initialState };
    default:
      return state;
  }
};