import "cfa-react-components/dist/styles.min.css";
import React, { Component, useEffect } from "react";
import HomePage from "./app/containers/HomePage/HomePage";
import { BrowserRouter as Router, Route } from "react-router-dom";
import history from "./app/utils/history";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import Login from "./app/pages/Login/Login";
import AuthenticatedRoute from "./app/pages/AuthenticatedRoute/AuthenticatedRoute";
const oktaAuth = new OktaAuth ({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/implicit/callback",
  scopes: [
    "address",
    "email",
    "location-viewer-api:read",
    "openid",
    "phone",
    "profile",
  ],
});

const restoreOriginalUri = (_oktaAuth, originalUri) => {
  history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  window.location.reload();
};

class App extends Component {
  onAuthRequired() {
    oktaAuth.signInWithRedirect({ originalUri: "/" });
  }

  render() {
    return (
      <div>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Security
                oktaAuth={oktaAuth}
                onAuthRequired={this.onAuthRequired}
                restoreOriginalUri={restoreOriginalUri}
              >
                <SecureRoute path="/" exact={true} component={HomePage} />
                <SecureRoute
                  path="/authenticated-route"
                  exact={true}
                  component={AuthenticatedRoute}
                />
                <Route path="/implicit/callback" component={LoginCallback} />
                <Route path="/Home" component={HomePage}>
                  {" "}
                </Route>
              </Security>
            </Router>
          </PersistGate>
        </Provider>
      </div>
    );
  }
}

export default App;
