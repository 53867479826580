import { endUserSession } from '../../actions';
import { store } from '../../store'

export default error => {
  const { status } = error;
  console.log('API Error:', error)

  // TODO: wire up specific actions to respond to specific HTTP status error codes
  // switch (status) {
  //   case 401:
  //   // Expired or bad access token
  //   case 403:
  //   // Unauthorized
  //   case 500:
  //   case 503:
  //   case 504:
  //   default:
  //   // show error modal
  // }
  return error;
}

// Method to end user session, can be called on a 401
const terminateSession = async () => {
  // Dispatch endSession auth action creator to end user session
  store.dispatch(endUserSession());
}