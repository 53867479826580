import React, { useState, useEffect, useRef } from "react";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { Divider, Modal, ModalHeader, ModalFooter, ModalBody, IconButton, Icon, LoadingIndicator } from 'cfa-react-components';

import './TableEntry.scss';


const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) {
        func();
      } else {
        didMount.current = true;
      }
    }, deps);
  };


const TableEntry = ({setClickedMarker, propertiesToDisplay, item}) => {
    const myRef = React.createRef();
    const [show, setShow] = useState(false)
    const [hide, setHide] = useState(true)
    const [unFormattedResponse, setUnformattedResponse] = useState([])
    const [formattedResponse, setFormattedResponse] = useState()
    const [isLoading, setIsLoading] = useState(true)

    // Handles the closing of modals
    function handleClose() {
        setShow(false)
    }

    // Handles opening of Modals
    function handleOpen() {
        setShow(true)
    }

    // Opens the modal and grabs the information to display 
    function handleModalText() {
      handleOpen()
      getInfo(item['locationNumber'])
      }

    // Handles the functionality when user clicks the pin icon next to an entry
    function handleClickedMarker() {
      setClickedMarker(item)
    }

    // API Call to get all the information for a single store
    function getInfo(storeNum) {
          const token = getToken()
          fetch(process.env.REACT_APP_LOCATION_API_GET_SPECIFIC_STORE + storeNum, {
            method: 'get',
            headers: new Headers({
              'Authorization': 'Bearer ' + token
            })
          }).then((res) => res.json().then((data) => {
            setUnformattedResponse(data['locationList'][0])
            setIsLoading(false)
          }))
    }

    //Does initial formatting of API response
    useDidMountEffect(() => {
    
        let formatString =  JSON.stringify(unFormattedResponse,null,6).replace(/['"]+/g, '')
        formatString =  formatString.replace(/[{}]+/g, '')
        formatString =  formatString.replace(/[,]+/g, '')
        
        setFormattedResponse(formatString)
  }, [unFormattedResponse])

    // Gets OKTA token for API
    function getToken() {
      const oktaCookie  = localStorage.getItem('okta-token-storage');
      let accessToken   = '';
    
      if (oktaCookie) {
          const token = JSON.parse(oktaCookie);
          accessToken = token.accessToken.accessToken;
      }
    
      return accessToken
    }

    // Replaces CamelCase words with better format (camelCase becomes Camel Case)
    function removeCamelCase(str) {
      str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
      str = str.charAt(1).toUpperCase() + str.slice(2);
      return str;
    }

      // Does all of the HTML styling and formatting for our API response 
      function renderBoldWords() {
        let reg = /\".*\":/g;
        let outerTabReg = / {18}/g;
        let innerTabReg = /- {6}/g;
        let whiteSpaceAndHyphens = /^\s*-\s*$/gm;
        let remodelAndSpaces = / {16}- R/g;


        let formatString = JSON.stringify(unFormattedResponse,null,6)//.replace(reg, '<b>' + reg + '</b>')

        formatString = formatString.replace(/[[]]+/g, 'none');
        formatString = formatString.replace(/[{}]+/g, '');
        formatString = formatString.replace(/[,]+/g, '');
        formatString = formatString.replace(outerTabReg, "             - ");
        formatString = formatString.replace(innerTabReg, "   - ");
        formatString = formatString.replace(whiteSpaceAndHyphens, '');
        formatString = formatString.replace(/\[/g,'');
        formatString = formatString.replace(/\]/g,'');


        let matchString = formatString.match(reg)
        for (var entry in matchString) {
            let regAll = new RegExp(matchString[entry], 'g')
            formatString = formatString.replaceAll(regAll, '<b>' + removeCamelCase(matchString[entry]) + '</b>')
        }

        formatString =  formatString.replaceAll(/['"]+/g, '')
        formatString = formatString.replaceAll('Core', 'Core Information')
        formatString = formatString.replaceAll('Address1', 'Address Line 1')
        formatString = formatString.replaceAll('Address2', 'Address Line 2')
        formatString = formatString.replaceAll('false', 'No')
        formatString = formatString.replaceAll('true', 'Yes')

        // These "formatString" variables contain a mix of HTML and text that is used to render
        // the text in a better format with some styling. This is the only way we could find to do something like this

        let n = formatString.toLowerCase().indexOf('location number')
        formatString = '<span name="general-info" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n<b style='text-align:center;'>\t\t\tGeneral Information</b>\n\n" + '</span><b>'+formatString.substring(n);

        n = formatString.toLowerCase().indexOf('location staff roles')
        formatString = formatString.substring(0,n)+ '<span name="staff-roles" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n\t\t\tSupport Staff\n\n" + '</span>'+formatString.substring(n);

        n = formatString.toLowerCase().indexOf('remodel history')
        formatString = formatString.substring(0,n)+'<span name="location-specifics" style="font-size:23px; font-weight:1000; text-decoration:underline;">' + "\n\t\t\tLocation Specifics\n\n" + '</span>'+formatString.substring(n);

        // The reason this looks weird, is that React forces you to format it like this
        // whenever you're directly injecting HTML into a component to make sure you mean to do it that way
        return {__html: formatString};
    }

  return (
    <ScrollSyncPane>
    <div className='table-entry-section'>
    <IconButton style={{minWidth: '3%', maxWidth: '3%', alignSelf: 'center', display: propertiesToDisplay.length > 0 ? 'inline' : 'none'}} onClick={e => handleModalText()}
    color="default"
    href=""
    size="sm"
  >
    <Icon className="table-entry-info-icon" icon="info-outline" />
    </IconButton>

    <IconButton style={{minWidth: '3%', maxWidth: '3%', alignSelf: 'center', display: propertiesToDisplay.length > 0 ? 'inline' : 'none'}} onClick={e => handleClickedMarker()}
    color="default"
    href=""
    size="sm"
  >
    <Icon className="table-entry-info-icon" icon="pin-outline" />
    </IconButton>

    {propertiesToDisplay
              .map((prop, index) => (
              <div id='prop' key = {index} className='table-entry-field'>  {String(item[prop]) != '' && String(item[prop]) != 'undefined'  ? String(item[prop]) : '~'}</div>
    ))}


    <Modal style={{overflow: 'none'}}
        onClose={e => handleClose()}
        size="lg"
        scrollMode="page"
        show={show}
    >
        <React.Fragment key=".0">
        <div className="my-modal-header">
          <ModalHeader style={{color: '#004F71'}}>
              Detailed Information for {item['locationName']}
          </ModalHeader>
        </div>
          <ModalBody style={{padding:'0%'}}>
              <div className="modal-body">
                  <div className="info-menu">
                      <h className='nav-bar-modal-title'>SECTIONS</h>
                      <a style={{ cursor: "pointer" }} className='nav-bar-modal' onClick={e => (document.getElementsByName('general-info')[0]).scrollIntoView({behavior: 'smooth'})}>General Info</a>
                      <a style={{ cursor: "pointer" }} onClick={e => (document.getElementsByName('staff-roles')[0]).scrollIntoView({behavior: 'smooth'})} className='nav-bar-modal'>Support Staff</a>
                      <a style={{ cursor: "pointer" }} onClick={e => (document.getElementsByName('location-specifics')[0]).scrollIntoView({behavior: 'smooth'})} className='nav-bar-modal'>Location Specifics</a>
                  </div>
                  <Divider orientation="vertical" variant="fullLength"/>
                  <div className="info-body">
                      {/* "dangerouslySetInnerHTML" may look pretty spooky, but it is the only way that React allows us
                          to directly render HTML inside a string in a component. Without this, the <bold> tags within
                          our formatted text just would not work, so it is necessary */}
                      <pre id='ModalText' className='more-information' dangerouslySetInnerHTML={renderBoldWords()} style={{display: isLoading ? 'none' : 'inline'}}>
                      </pre>
                      <div className="loading-page-entry" style={{display: isLoading ? 'flex' : 'none'}}>
                        <LoadingIndicator
                          size="lg"
                          variant="page"
                        />
                      </div>
                  </div>
              </div>
          </ModalBody>
        </React.Fragment>
    </Modal>
</div>
</ScrollSyncPane>
  )
}


export default TableEntry
