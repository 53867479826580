import React from 'react'
import Lottie from "react-lottie"
import noResults from '../../assets/animations/no-results-found.json'
import '../Table/Table.scss'



const noResultsAnimation ={
  loop: true,
  autoplay: true, 
  animationData: noResults, 
  renderSettings: { 
    preserveAspectRatio: "xMidYMid slice",
  },
}



const NoData = () => {
  return (
    <div className="no-data">
      <Lottie 
      options = {noResultsAnimation}
      height="70%"
      width="30%"
      />
      No Results Found!
    </div>
  )
}

export default NoData
