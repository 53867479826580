import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import logo from '../../assets/icons/cfa-okta-logo.svg';
import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import I18n from '../../../i18n/utils';

import './Login.scss';

export default class OktaSignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    const config = {
      baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
      logo,
      authParams: {
        pkce: true,
        responseType: ['token', 'id_token']
      },
      i18n: {
        'en': {
          'primaryauth.title': I18n.t('APP_SHORT_NAME'),
          'primaryauth.submit': I18n.t('APP_SIGN_IN'),
        }
      },
      pkce: true
    }

    this.widget = new OktaSignIn(config);
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div className="Login" />;
  }
};