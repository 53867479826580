export default {
  // APP
  APP_NAME: process.env.REACT_APP_NAME,
  APP_SHORT_NAME: process.env.REACT_APP_NAME,
  APP_SIGN_IN: 'Sign in',

  // <b></b> tags parse-able with the parse() plugin from react-html-parser

  APP_UNAUTHORIZED_MESSAGE: 'Unauthorized to view this Application',
  APP_UNDEFINED_LOCATION_MESSAGE: 'Please search for a location',

  // Nav Menu
  APP_SHORT_TITLE: process.env.REACT_APP_NAME,
  APP_MENU_LANDING: 'Landing',
  APP_MENU_SIGN_OUT: 'Sign out',
  APP_PROFILE_TOOLTIP: 'Profile',
  APP_SELECT_LOCATION: 'Select Location',

  // Landing Page
  APP_LANDING_PAGE_WELCOME: 'Welcome',
  APP_LANDING_PAGE_SELECT_APP: 'Select an Application',
  APP_LANDING_PAGE_SELECT_LOCATION: 'Please select a location to continue',
  APP_LANDING_PAGE_TITLE: `${process.env.REACT_APP_NAME} Home`,

  // Location Selector
  APP_LOCATION_SELECTOR_SELECT: 'Please Select a location',

  // Authenticated Route
  APP_AUTHENTICATED_ROUTE_TITLE: 'Authenticated Route'

  // Add more routes/pages/components' translations below
}