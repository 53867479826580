import React, { useState } from "react";
import { Icon, IconButton, Modal, ModalBody, ModalHeader, Tooltip, Divider } from "cfa-react-components";
import "./Help.scss";

const Help = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Tooltip content="Need Help?" showOnElementEvents={["hover"]}>
        <IconButton
          className="icon-style"
          onClick={(e) => setShow(true)}
          color= '#002F43'
          size="md"
          style={{width: 'fit-content', alignSelf: 'center', color: '#002F43'}}
        >
        <div className="light-bulb-icon">
          <Icon icon="bulb" />
        </div>
        </IconButton>
      </Tooltip>
      <Modal
        onClose={(e) => setShow(false)}
        size="lg"
        show={show}
      >
        <React.Fragment key=".0">
          <ModalHeader style={{ backgroundColor: "#ECEDEE", padding: "3%", color: "#004F71", fontSize: "40px", borderRadius: "8px" }} >
            Help Menu
          </ModalHeader>
          <ModalBody>
            <div className="icon-help">
              <div className="help-section">
                <div className="help-element">
                  <Icon icon="pencil" />
                  <span style={{ marginLeft:"8px" }}>Customize columns displayed on table</span>
                </div>
                <div>
                  <Icon icon="download" />
                  <span style={{ marginLeft:"8px" }}>Download contents of table as .csv file (Excel)</span>
                </div>
                <div>
                  <Icon icon="info-outline" />
                  <span style={{ marginLeft: "4px" }}> Display all restaurant information </span>
                </div>
                <div>
                  <Icon icon="pin-outline" />
                  <span style={{ marginLeft: "8px"}}>Scroll page to map</span>
                </div>
              </div>
            </div>
            <Divider variant="middle"/>
            <div className="map-help">
              <div className="text">
                <p> To view contents of the table on a map, scroll to bottom of page or click pin icon.</p>
                <p> Type in search bar to search reductively for a restaurant within the current table. (initial table contains information about every restaurant)</p>
                <p> Press enter when searching to create search tags. Search tags work additively (multiple tags will select all restaurants that match with any current tags)</p>
                <p> Click on a table header to sort by values in that column. </p>
              </div>
              <div className="text-footer">
                For user feedback and bug fixes, open an issue on our <a href="https://github.com/cfacorp/location-viewer/issues">repo</a> <br/>
                or submit a request on this <a href="https://forms.office.com/r/uk7BPjcj8Z">form</a>
              </div>
            </div>
          </ModalBody>
        </React.Fragment>
        <div style={{height: "20px"}}/>
      </Modal>
    </>
  );
};

export default Help;



/*

This code is a React component that displays a help menu when an icon button is clicked. 
The component imports several components from the cfa-react-components library, including IconButton, Modal, ModalHeader, ModalBody, Tooltip, and Divider. 
The component uses the useState hook to set the show state to false initially. When the IconButton is clicked, 
the show state is set to true, which triggers the Modal component to open. 
The ModalHeader component displays a title for the modal, and the ModalBody component 
contains two sections of text with helpful information about the application. The first section 
explains how to customize columns displayed on the table, download contents of the table as a .csv file, 
display all restaurant information, and scroll to the map. The second section provides instructions on 
how to search for restaurants within the current table, sort by values in a column, and submit feedback or bug fixes.

*/
