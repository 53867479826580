// eslint - disable import /first
import handleError from './APIError';
import { store } from '../../store';
import axios from 'axios';

// Init Axios Instance
let axiosInstance = axios.create();

axiosInstance.interceptors.request.use(res => {
  return res;
}, error => {
  // Send Error to handler
  const handledError = handleError(error);
  return Promise.reject(handledError);
});

axiosInstance.interceptors.response.use(res => {
  return res;
}, error => {
  // Send Error to handler
  const handledError = handleError(error);
  return Promise.reject(handledError);
});

const getHeaders = async () => {
  // Get latest accessToken from Redux
  const { accessToken } = await store.getState().auth;

  return {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'cache-control': 'no-cache',
      'Authorization': `Bearer ${accessToken}`
    },
  };
};

export const API_GET = async (path) => {
  return await axiosInstance.get(path, await getHeaders());
};

export const API_POST = async (path, body) => {
  return await axiosInstance.post(path, body, await getHeaders());
};

export const API_PATCH = async (path, body) => {
  return await axiosInstance.patch(path, body, await getHeaders());
};

export const API_PUT = async (path, body) => {
  return await axiosInstance.put(path, body, await getHeaders());
};

export const API_DEL = async (path) => {
  return await axiosInstance.delete(path, await getHeaders());
};