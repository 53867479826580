import {
  SET_ACCESS_TOKEN,
  //
  SET_USER_TOKEN,
  //
  END_USER_SESSION,
  setAssignedLocations
} from '../actions';

const OKTA_WAS_TAG = process.env.REACT_APP_OKTA_WAS_TAG;

export const setUserSession = (accessToken, userToken) => {
  return dispatch => {
    // Method to set all User session data
    dispatch(setAccessToken(accessToken));
    dispatch(updateUserPermissions(accessToken, userToken));
  }
}

// Update User Token with Permissions
const updateUserPermissions = (accessToken, userToken) => {
  return dispatch => {
    const jwt = parseJwt(accessToken);
    console.log(jwt)
    const cfaPerms = jwt['cfa_perms'][OKTA_WAS_TAG]; // The name of the OKTA WAS App tag goes here
    // Assign Locations
    const assignedLocations = cfaPerms['LOGIN'];
    // Determine if user is a staff
    const isStaff = assignedLocations.indexOf('00000') !== -1 ? true : false;
    userToken['isStaff'] = isStaff;
    // Update Assigned Locations
    dispatch(setAssignedLocations(assignedLocations, isStaff));
    // Update User Token in Redux
    dispatch(setUserToken(userToken));
  }
}

// Method to parse JWT token
const parseJwt = (token) => {
  let json;
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    json = JSON.parse(window.atob(base64));
  } catch (e) {
    console.error('error parsing jwt ' + String(e));
  }
  return json;
}

const setAccessToken = (accessToken) => {
  return dispatch => {
    dispatch({ type: SET_ACCESS_TOKEN, payload: accessToken });
  }
}

const setUserToken = (userToken) => {
  return dispatch => {
    dispatch({ type: SET_USER_TOKEN, payload: userToken });
  }
}

export const endUserSession = () => {
  return dispatch => {
    dispatch({ type: END_USER_SESSION });
  }
}