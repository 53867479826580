import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';

import './BackArrow.scss';

const BackArrow = ({ title, path }) => {

  const linkTitle = title ? title : 'Back';

  const routePath = path ? path : '/';

  return (
    <Row className="container">
      <Link
        to={routePath}
        className="desktop-back-container">
        <img
          alt=""
          src={require('../../../assets/icons/left-arrow.svg')}
          height={14}
          width={14}
          className="desktop-back-arrow"
        />
        <span className="desktop-back-link">{linkTitle}</span>
      </Link>
    </Row>
  );
};

BackArrow.propTypes = {
  title: PropTypes.string.isRequired,
};

export { BackArrow };