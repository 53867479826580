export const Tooltips = {
    "Location Number": "Restaurant Number",
    "Location Name": "Restaurant Name",
    "Physical Address": "Restaurant Address",
    "Location Status": "Permanent Open/Closed Status",
    "Temp Close Flag": "Whether Restaurant is Temporarily Closed",
    "Location Format Type": "Restaurant Type",
    "Time Zone Name": "Restaurant Time Zone",
    "Accountant Name": "Primary Restaurant Accountant",
    "Accountant GUID": "Primary Accountant GUID",
    "Accountant ID": "Primary Accountant ID",
    "Accounts Receivable GUID": "Accounts Receivable GUID",
    "Accounts Receivable ID": "Accounts Receivable ID",
    "Accounts Receivable Name": "Accounts Receivable Name (Last, First)",
    "Area Director GUID": "Area Director GUID",
    "Area Director ID": "Area Director ID",
    "Area Director Name": "Area Director Name (Last, First)",
    "Billing Address": "Full Billing Address",
    "Billing Address Line 1": "Billing Address Line 1",
    "Billing Address Line 2": "Billing Address Line 2",
    "Billing Address Line 3": "Billing Address Line 3",
    "Billing Address City": "Billing City",
    "Billing Address Country Name": "Billing Country",
    "Billing Address County": "Billing County",
    "Billing Address Iso Country Code": "Billing Country Code",
    "Billing Address State": "Billing State",
    "Billing Address Zip": "Billing Zip Code",
    "Billing Address Zip Extension": "Billing Zip Code Extension",
    "Business Consultant GUID": "Business Consultant GUID",
    "Business Consultant ID": "Business Consultant ID",
    "Business Consultant Name": "Billing Consultant Name (Last, First)",
    "Business Development Representative Licensee GUID": "Business Development Representative Licensee GUID",
    "Business Development Representative Licensee ID": "Business Development Representative Licensee ID",
    "Business Development Representative Licensee Name": "BDRL Name (Last, First)",
    "Company": "Affiliate Company",
    "Concept Type": "CFA: Chick-fil-A, LIC: Licensee, DWH: Dwarf House",
    "Confidential Site": "Whether restaurant is confidential",
    "Counter Type": "Counter Type",
    "Daytime Phone Number": "Daytime Phone Number",
    "DC Existing Restaurant GUID": "DC Existing Restaurant GUID",
    "DC Existing Restaurant ID": "DC Existing Restaurant ID",
    "DC Existing Restaurant Name": "DC Existing Restaurant Name",
    "DC New Restaurant GUID": "DC New Restaurant GUID",
    "DC New Restaurant ID": "DC New Restaurant ID",
    "DC New Restaurant Name": "DC New Restaurant Name",
    "Deliveries Fulfilled By": "Delivery Fulfillment Service",
    "Digital Field Service Tech GUID": "Digital Field Service Tech GUID",
    "Digital Field Service Tech ID": "Digital Field Service Tech ID",
    "Digital Field Service Tech Name": "Digital Field Service Tech Name (Last, First)",
    "Dining Room": "Whether Restaurant Has Dining Room",
    "Direct Market Code": "Direct Market Code",
    "Director Of Field Operations GUID": "Director Of Field Operations GUID",
    "Director Of Field Operations ID": "Director Of Field Operations ID",
    "Director Of Field Operations Name": "Director of Field Operations Name (Last, First)",
    "Director Satellite Restaurant GUID": "Director Satellite Restaurant GUID",
    "Director Satellite Restaurant ID": "Director Satellite Restaurant ID",
    "Director Satellite Restaurant Name": "Director Satellite Restaurant Name (Last, First)",
    "Email Address": "Restaurant Email Address",
    "Equipment Representative GUID": "Equipment Representative GUID",
    "Equipment Representative ID": "Equipment Representative ID",
    "Equipment Representative Name": "Equipment Representative Name (Last, First)",
    "ERQA Representative GUID": "ERQA Representative GUID",
    "ERQA Representative ID": "ERQA Representative ID",
    "ERQA Representative Name": "ERQA Representative Name (Last, First)",
    "Facilities Area Field Consultant GUID": "Facilities Area Field Consultant GUID",
    "Facilities Area Field Consultant ID": "Facilities Area Field Consultant ID",
    "Facilities Area Field Consultant Name": "Facilities Area Field Consultant Name (Last, First)",
    "Facilities Project Coordinator Name": "Facilities Project Coordinator Name (Last, First)",
    "Facilities Project Coordinator GUID": "Facilities Project Coordinator GUID",
    "Facilities Project Coordinator ID": "Facilities Project Coordinator ID",
    "Fax Number Phone Number": "Restaurant Fax Number",
    "Field Operations Contact": "Field Operations Contact",
    "Financial Consultant GUID": "Financial Consultant GUID",
    "Financial Consultant ID": "Financial Consultant ID",
    "Financial Consultant Name": "Financial Consultant Name (Last, First)",
    "Fm Tech Consultant GUID": "Fm Tech Consultant GUID",
    "Fm Tech Consultant ID": "Fm Tech Consultant GUID",
    "Fm Tech Consultant Name": "Fm Tech Consultant Name (Last, First)",
    "Food Safety Operations Lead GUID": "Food Safety Operations Lead GUID",
    "Food Safety Operations Lead ID": "Food Safety Operations Lead ID",
    "Food Safety Operations Lead Name": "Food Safety Operations Lead Name (Last, First)",
    "Food Safety Report Representative GUID": "Food Safety Report Representative GUID",
    "Food Safety Report Representative ID": "Food Safety Report Representative ID",
    "Food Safety Report Representative Name": "Food Safety Report Representative Name (Last, First)",
    "Food Truck Flag": "Whether Restaurant has a Food Truck",
    "Franchisee Selection Consultant": "Franchisee Selection Consultant",
    "Fulfilling Deliveries For": "Fulfilling Deliveries For",
    "GMT Offset": "Time offset from GMT",
    "Group Number": "Group Number",
    "Has Drive Thru": "Whether restaurant has a drive-thru",
    "Kitchen Install Date": "Date of Kitchen Installation",
    "Kitchen Type": "Kitchen Layout",
    "Land Purchased Flag": "Whether Restaurant's Land is Purchased",
    "LDP Consultant GUID": "LDP Consultant GUID",
    "LDP Consultant ID": "LDP Consultant ID",
    "LDP Consultant Name": "LDP Consultant Name (Last, First)",
    "Lease Accountant GUID": "Lease Accountant GUID",
    "Lease Accountant ID": "Lease Accountant ID",
    "Lease Accountant Name": "Lease Accountant Name (Last, First)",
    "Licensed Business Consultant GUID": "Licensed Business Consultant GUID",
    "Licensed Business Consultant ID": "Licensed Business Consultant ID",
    "Licensed Business Consultant Name": "Licensed Business Consultant Name (Last, First)",
    "Location Sub Type Code": "Tririga Subtype Code Abbreviation",
    "Location Type Code": "DTO: Drive-Thru Only, FSU: Free Standing Restaurant, DH: Dwarf House, SAT: other",
    "Market Level Market Code": "Market Name Abbreviation",
    "Market Name": "Market Name (Associated Major City)",
    "Marketing Consultant GUID": "Marketing Consultant GUID",
    "Marketing Consultant ID": "Marketing Consultant ID",
    "Marketing Consultant Name": "Marketing Consultant Name (Last, First)",
    "Number of Approach Lanes": "Number of Drive-thru Lanes",
    "Number of Order Points": "Number of POS points",
    "Number of Pickup Windows": "Number of Pickup Lanes",
    "Open Date": "Opening Date",
    "Operations Lead GUID": "Operations Lead GUID",
    "Operations Lead ID": "Operations Lead ID",
    "Operations Lead Name": "Operations Lead Name (Last, First)",
    "Operator GUID": "Operator GUID",
    "Operator Name": "Operator Name",
    "Operator Selection Timeline": "Operator Selection Timeline",
    "Operator Team Code": "Operator Team Code",
    "Operator Team Name": "Operator Team Name",
    "Payroll Tax Acccountant GUID": "Payroll Tax Acccountant GUID",
    "Payroll Tax Acccountant ID": "Payroll Tax Acccountant ID",
    "Payroll Tax Acccountant Name": "Payroll Tax Acccountant Name (Last, First)",
    "Pending Opportunity": "Whether Location is Pending",
    "Person ID": "Person ID",
    "Physical Address Line 1": "Restaurant Address Line 1",
    "Physical Address Line 2": "Restaurant Address Line 2",
    "Physical Address Line 3": "Restaurant Address Line 3",
    "Physical Address City": "Restaurant City",
    "Physical Address Country Name": "Restaurant Country Name",
    "Physical Address County": "Restaurant County",
    "Physical Address Iso Country Code": "Restaurant Country ISO Code",
    "Physical Address Latitude": "Restaurnt Latitude",
    "Physical Address Longitude": "Restaurant Longitude",
    "Physical Address State": "Restaurant State",
    "Physical Address Zip": "Restaurant Zip Code",
    "Physical Address Zip Extension": "Restaurant Zip Code Extension",
    "Playground": "Whether Restaurant Has Playground",
    "POS Tax Calc Method": "POS Tax Calculation Method", 
    "Projected Open Date": "Projected Opening Date",
    "Property Management Analyst GUID": "Property Management Analyst GUID",
    "Property Management Analyst ID": "Property Management Analyst ID",
    "Property Management Analyst Name": "Property Management Analyst Name (Last, First)",
    "Property Tax Accountant GUID": "Property Tax Accountant GUID",
    "Property Tax Accountant ID": "Property Tax Accountant ID",
    "Property Tax Accountant Name": "Property Tax Accountant Name (Last, First)",
    "Prototype Code": "Prototype Code",
    "Quality Improvement Visit Representative GUID": "Quality Improvement Visit Representative GUID",
    "Quality Improvement Visit Representative ID": "Quality Improvement Visit Representative ID",
    "Quality Improvement Visit Representative Name": "Quality Improvement Visit Representative Name (Last, First)",
    "RD Warranty Specialist GUID": "RD Warranty Specialist GUID",
    "RD Warranty Specialist ID": "RD Warranty Specialist GUID",
    "RD Warranty Specialist Name": "RD Warranty Specialist Name (Last, First)",
    "Real Estate Developer GUID": "Real Estate Developer GUID",
    "Real Estate Developer ID": "Real Estate Developer ID",
    "Real Estate Developer Name": "Real Estate Developer Name (Last, First)",
    "Recruit Phase0 Date": "Phase 0 Recruitment Date",
    "Recruit Phase1 Date": "Phase 1 Recruitment Date",
    "Recruit Phase2 Date": "Phase 2 Recruitment Date",
    "Recruit Phase3 Date": "Phase 3 Recruitment Date",
    "Recruit Phase4 Date": "Phase 4 Recruitment Date",
    "Recruit Phase5 Date": "Phase 5 Recruitment Date",
    "Recruit Phase6 Date": "Phase 6 Recruitment Date",
    "Region Admin GUID": "Region Admin GUID",
    "Region Admin ID": "Region Admin ID",
    "Region Admin Name": "Region Admin Name (Last, First)",
    "Region Code": "Region Code",
    "Region Lead GUID": "Region Lead GUID",
    "Region Lead ID": "Region Lead ID",
    "Region Lead Name": "Region Lead Name (Last, First)",
    "Region Name": "Region Name",
    "Relocated From": "Former Physical Address",
    "Relocation Flag": "Whether Restaurant is a Relocation",
    "Remodel History": "Remodel History",
    "Restaurant Type": "Restaurant Type",
    "Rest Trans Lead GUID": "Restaurant Transition Lead GUID",
    "Rest Trans Lead ID": "Restaurant Transition Lead ID",
    "Rest Trans Lead Name": "Restaurant Transition Lead Name (Last, First)",
    "Restaurant Cash Management Operations GUID": "Restaurant Cash Management Operations GUID",
    "Restaurant Cash Management Operations ID": "Restaurant Cash Management Operations ID",
    "Restaurant Cash Management Operations Name": "Restaurant Cash Management Operations Name (Last, First)",
    "Safe Daily Critical Representative GUID": "Safe Daily Critical Representative GUID",
    "Safe Daily Critical Representative ID": "Safe Daily Critical Representative ID",
    "Safe Daily Critical Representative Name": "Safe Daily Critical Representative Name (Last, First)",
    "Sales Tax Accountant GUID": "Sales Tax Accountant GUID",
    "Sales Tax Accountant ID": "Sales Tax Accountant ID",
    "Sales Tax Accountant Name": "Sales Tax Accountant Name (Last, First)",
    "Satellite Restaurant Consultant GUID": "Satellite Restaurant Consultant GUID",
    "Satellite Restaurant Consultant ID": "Satellite Restaurant Consultant ID",
    "Satellite Restaurant Consultant Name": "Satellite Restaurant Consultant Name (Last, First)",
    "Service Team Captain GUID": "Service Team Captain GUID",
    "Service Team Captain ID": "Service Team Captain ID",
    "Service Team Captain Name": "Service Team Captain Name (Last, First)",
    "Service Team Code": "Service Team Code",
    "Service Team Name": "Service Team Name",
    "Shipping Address": "Restaurant Shipping Address",
    "Shipping Address Line 1": "Restaurant Shipping Address Line 1",
    "Shipping Address Line 2": "Restaurant Shipping Address Line 2",
    "Shipping Address Line 3": "Restaurant Shipping Address Line 3",
    "Shipping Address City": "Restaurant Shipping Address City",
    "Shipping Address Country Name": "Restaurant Shipping Address Country",
    "Shipping Address County": "Restaurant Shipping Address County",
    "Shipping Address Iso Country Code": "Restaurant Shipping Address Country Code",
    "Shipping Address State": "Restaurant Shipping Address State",
    "Shipping Address Zip": "Restaurant Shipping Address Zip Code",
    "Shipping Address Zip Extension": "Restaurant Shipping Address Zip Code Extension",
    "SS Participation": "Smart Safe Participation",
    "Supply Chain Consultant GUID": "Supply Chain Consultant GUID",
    "Supply Chain Consultant ID": "Supply Chain Consultant ID",
    "Supply Chain Consultant Name": "Supply Chain Consultant Name (Last, First)",
    "Tax Calculation Location Number": "Location Number for Tax Calculation",
    "Team Admin GUID": "Team Admin GUID",
    "Team Admin ID": "Team Admin ID",
    "Team Admin Name": "Team Admin Name (Last, First)",
    "Team Director GUID": "Team Director GUID",
    "Team Director ID": "Team Director ID",
    "Team Director Name": "Team Director Name (Last, First)",
    "Technology Advisor GUID": "Technology Advisor GUID",
    "Technology Advisor ID": "Technology Advisor ID",
    "Technology Advisor Name": "Technology Advisor Name (Last, First)",
    "Time Zone ID": "Time Zone ID",
    "Treasury Accountant GUID": "Treasury Accountant GUID",
    "Treasury Accountant ID": "Treasury Accountant ID",
    "Treasury Accountant Name": "Treasury Accountant Name (Last, First)",
    "Voice Mail": "Voice Mail Number"
}