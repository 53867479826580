import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Map,
  Tooltip,
  useMapEvent,
  useMap
} from "react-leaflet";

import MapModal from "./MapModal/MapModal";
import MarkerClusterGroup from 'react-leaflet-markercluster';

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

function MapMarker({restaurant, markerRef, setDisplayedStore, setShowModal, clickedMarker}) {
  const map = useMap()

  useDidMountEffect ( () => {
    map.flyTo([clickedMarker.physicalAddressLatitude, clickedMarker.physicalAddressLongitude], 14, {
      animate: true,
      duration: 1.5
    });
  }, [clickedMarker])

  return (
  <Marker
    position={[
      restaurant.physicalAddressLatitude,
      restaurant.physicalAddressLongitude,
    ]}
    ref={markerRef}
    eventHandlers={{
      click: (e) => {setDisplayedStore(restaurant); setShowModal(true);}
      //map.setView([restaurant.physicalAddressLatitude, restaurant.physicalAddressLongitude], 25)
    }}
  >
    <Tooltip>
      {restaurant.locationName}
    </Tooltip>
  </Marker>
  )
}



const Maps = ({ mapPins, numResults, clickedMarker }) => {
  const position = [37.0902, -95.7129];
  const [showModal, setShowModal] = useState(false);
  const markerRef = useRef(null);
  const [displayedStore, setDisplayedStore] = useState([]);

  // useEffect(() => {
  //   const marker = markerRef.current;
  //   if (marker) {
  //     marker.addEventListener("click", () => {
  //       setShowModal(true);
  //     });
  //   }
  // }, [markerRef]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <div style={{width: '100%'}}>
      <MapContainer classList="map" center={position} zoom={4} scrollWheelZoom={true} style={{width: '100%'}}>
        <TileLayer
          attribution= '&copy; <a href="https://carto.com/">carto.com</a> contributors'
          url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
        />
        {/* <MarkerClusterGroup
          maxClusterRadius={200}
        > */}
        {mapPins.slice(0, 800).map((restaurant, index) => (
          <div key={index}>
            <MapMarker restaurant={restaurant} markerRef={markerRef} setDisplayedStore={setDisplayedStore} setShowModal={setShowModal} clickedMarker={clickedMarker} key={index}/>
          </div>
        ))}
        {/* </MarkerClusterGroup> */}
      </MapContainer>
      {<MapModal modalIsDisplayed={showModal} closeModal={setShowModal} locationToDisplay={displayedStore} showModal={showModal} />}
    </div>
  );
};

export default Maps;



/*
This code is a React component that renders a map with markers. 
It imports the necessary components from the react-leaflet library, such as MapContainer, TileLayer, Marker, Popup, Map, Tooltip, useMapEvent, and useMap. 
It also imports a custom MapModal component. 

The useDidMountEffect hook is used to set the view of the map to the clicked marker when it is mounted. 
The MapMarker component is then rendered for each restaurant in the mapPins array, which contains the latitude and longitude of each restaurant. 
When a marker is clicked, the displayedStore state is set to the corresponding restaurant and the showModal state is set to true, which displays the MapModal component. 
Finally, the MapModal component is rendered with the modalIsDisplayed prop set to the showModal state, and the locationToDisplay prop set to the displayedStore state.
*/